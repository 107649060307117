import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from './router'
import vueHeadful from 'vue-headful'
import store from './store/store'
//import './registerServiceWorker'
import vueSmoothScroll from 'vue2-smooth-scroll'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGtag from "vue-gtag";
import { VueMasonryPlugin } from 'vue-masonry';

['push', 'replace'].forEach(method => {
  const originalMethod = router.__proto__[method];
  router.__proto__[method] = function m(location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        // capture exception
      }
    })
  };
});

sync(store, router);

Vue.use(VueGtag, {
  config: { id: "UA-44952673-52" }
}, router);

import VueFilterDateFormat from 'vue-filter-date-format';

Vue.use(VueFilterDateFormat, {
  monthNames: [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ],
  monthNamesShort: [
    'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun',
    'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDLNougvhmVReC-N0jb-GJVLQqGCD1T5F4',
    libraries: 'places,drawing,visualization', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    //// 3.36 FOR IE !!!
    v: '3.36',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.use(VueMasonryPlugin)

Vue.component('vue-headful', vueHeadful);
Vue.config.productionTip = false
Vue.use(vueSmoothScroll)
var VueScrollTo = require('vue-scrollto');
// You can also pass in the default options
Vue.use(VueScrollTo)

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
