<template>
  <div id="app">
    <div class="page-wrap" id="page-wrap">
      <div class="container-fluid">
        <header-menu></header-menu>
        <router-view />
      </div>
      <div class="push"></div>
    </div>
    <footer-menu></footer-menu>
    <cookie-law>
      <div slot-scope="props">
        <p class="small">
          Les cookies sont utilisés pour faire fonctionner ce site et améliorer votre expérience. Vous
          pouvez donner votre consentement en cliquant sur le bouton "Je consens" ou en annulant cet avis de cookie.
        </p>
        <a class="btn btn--sm btn--white color--blue" @click="props.close"><span>Annuler</span></a>
        <button class="btn btn--sm btn--yellow color--blue" @click="props.accept"><span>Je consens</span></button>
      </div>
    </cookie-law>
  </div>
</template>
<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import headerMenu from './views/elements/Header.vue';
import footerMenu from './views/elements/Footer.vue';
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'app',
  components: {
    headerMenu,
    footerMenu,
    CookieLaw
  },
  data: function(){
    return {
      scrollOptions: {
        container: "body",
        duration: 500,
        easing: "ease",
        offset: -150,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      }
    }
  },
  created: function(){
  }
}
</script>
<style lang="scss">
@import './assets/css/animate.css';
@import './assets/scss/theme.scss';
</style>
